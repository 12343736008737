.homepage-card-title-link {
  display: flex;
  justify-content: space-between;
}

.homepage-card-title {
  display: grid;
  grid-template-rows: 15px 27px 30px;
}

/* preserve space if text is empty */
.homepage-card-title-text span:empty::after,
.homepage-card-title-text:empty::after {
  content: ' ';
  white-space: pre;
}

.homepage-cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}

.homepage-card {
  max-width: 450px;
  justify-self: center;
}

.homepage-chart {
  [aria-label="tip"] {
    --plot-background: rgba(51, 51, 51, 0.9);
    color: var(--white-dark);
    stroke: var(--grey-dark);
  }
}

#gradient {
  animation: skeleton-loading 2s linear infinite;
}

@keyframes skeleton-loading {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}
