.button {
  --button-background: var(--white-light);
  --button-border: var(--grey-transparent);
  --button-color: var(--black);
  --button-radius: var(--border-radius);

  background: var(--button-background);
  border: 1px solid var(--button-border);
  border-radius: var(--button-radius);
  color: var(--button-color);

  box-sizing: border-box;
  cursor: pointer;
  font-family: var(--font-family-header);
  font-size: var(--font-size-root);
  font-weight: var(--font-weight-header);
  line-height: 1;
  margin: 0;
  max-width: 100%;
  white-space: nowrap; /* don't break icons over multiple lines */
  padding: var(--size-small) var(--size-medium);
  vertical-align: middle;

  [data-color-mode="dark"] & {
    --button-background: var(--black-light);
    --button-border: var(--black-transparent);
    --button-color: var(--white);
  }

  /** States **/
  &[aria-expanded="true"],
  &[aria-current="page"] {
    --button-border: var(--grey);
  }

  &:hover {
    --button-border: var(--grey);
  }

  &:active {
    --button-border: var(--black);
    --button-background: var(--grey-transparent);
  }

  &:focus-within {
    --button-background: var(--white);
    --button-color: var(--black);
    outline: 0;


    [data-color-mode="dark"] & {
      --button-background: var(--black-transparent);
      --button-border: var(--grey);
      --button-color: var(--white);
      outline: 0;
    }
  }
}

/** Non-CSS states **/
.button-active {
  --button-border: var(--black);

  &:hover {
    cursor: default;
  }

  [data-color-mode="dark"] & {
    --button-border: var(--black-transparent);
  }
}

.button[disabled],
.button-disabled,
.not-clickable {
  --button-color: var(--grey-light);
  --background-color: var(--white-transparent);
  opacity: 1;
  pointer-events: none;
}

/** Button variants **/
.button-primary {
  --button-background: var(--black);
  --button-border: var(--black);
  --button-color: var(--white-light);
  --button-radius: var(--border-radius);
  padding: var(--size-medium);
  font-weight: var(--font-weight-regular);
}

.button-primary:active,
.button-primary.button-active {
  --button-background: var(--grey-dark);
}

.button-primary.button[disabled],
.button-primary.button-disabled,
.button-primary.not-clickable {
  --button-background: var(--grey-dark);
  --button-color: var(--grey-light);
}

.button-primary:focus-within {
  --button-border: var(--grey);
}

.button-transparent {
  --button-background: transparent;
  --button-border: transparent;
  --button-color: var(--app-color);
  --button-radius: var(--border-radius);
}

.button-link {
  display: inline-block;
  text-decoration: none;
}

/* Buttons with tooltips */
.button-tooltip {
  padding: 0;
}

.button-text {
  display: inline-block;
  padding: var(--size-small) var(--size-medium);
}

/* Icon buttons */
.button-icon {
  padding: 0;
}

.button-icon .icon {
  display: inline-flex;
  padding: var(--size-small) var(--size-medium);
}

.button .icon-clear {
  border-color: var(--grey);
}

/* Nested buttons */
.button .button {
  --button-background: transparent;
  border: 0;
  border-radius: 0;
}

/* Block buttons */
.button.display-block {
  display: block;
  width: 100%;
}

/** Joined buttons (pills) **/
.button-pills {
  display: flex;
}

.button-pills > .button {
  --button-radius: 1000px;
  border-radius: 0;
}

.button-pills > .button ~ .button {
  margin-left: -1px;
}

.button-pills > .button-active {
  --button-background: var(--white);


  [data-color-mode="dark"] & {
    --button-background: var(--black-dark);
  }
}

.app-content > .button-pills > .button-active {
  --button-border: var(--grey-transparent);
}

.button-pills > .button:is(:hover, :focus-within, :active, .button-active) {
  position: relative;
  z-index: 1;
}

.button-pills > .button:first-of-type {
  border-top-left-radius: var(--button-radius);
  border-bottom-left-radius: var(--button-radius);
}

.button-pills > .button:last-of-type {
  border-top-right-radius: var(--button-radius);
  border-bottom-right-radius: var(--button-radius);
}

  /** Pill followed by Card (e.g. /user/settings) **/
.button-pills:has( + .card) {
  justify-content: center;
}

/** Dropdowns **/

.button-dropdown {
  --button-radius: 5px;
  line-height: var(--line-height-medium);
}

.button-dropdown-selected {}

/** Data source dropdown - join the datasource button with datasource types dropdown **/
.button-pills > .button-active:has(+ .button-dropdown) {
  border-right-color: var(--grey-transparent);

  [data-color-mode="dark"] & {
    border-right-color: var(--black-transparent);
  }
}

.button-pills > .button-active + .button-dropdown {
  --button-background: var(--white);
  --button-border: var(--black);
  border-left-color: var(--grey-transparent);

  [data-color-mode="dark"] & {
    --button-background: var(--black-dark);
    --button-border: var(--black-transparent);
    border-left-color: var(--white-transparent);
  }
}

.dropdown-list {
  --dropdown-background: var(--white-light);
  --dropdown-border: var(--grey-transparent);
  --dropdown-color: var(--app-color);
  --dropdown-focus: var(--black);
  --dropdown-icon: var(--grey-light);
  --dropdown-shadow: var(--app-header-shadow);
  --dropdown-item-background: transparent;
  --dropdown-item-color: currentColor;
  --dropdown-hr: var(--app-header-border);

  background: var(--dropdown-background);
  border: solid 1px var(--dropdown-border);
  box-shadow: 0px 12px 12px -12px var(--dropdown-shadow);
  border-radius: var(--border-radius);
  color: var(--dropdown-color);

  margin-top: var(--size-small);
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  animation: slideDown .18s ease-in-out;

  [data-color-mode="dark"] & {
    --dropdown-background: var(--black-dark);
    --dropdown-border: var(--black);
  }
}

.dropdown-list:focus-within {
  outline: 0;
}

  /** Options (selectable items) **/
  .dropdown-list-option {
    background: var(--dropdown-item-background);
    border-bottom: 1px solid var(--dropdown-item-border);
    color: var(--dropdown-item-color);

    cursor: pointer;
    display: flex;
    align-items: center;
    font: inherit;
    line-height: 1;
    text-decoration: initial;

    position: relative;
  }

    /** Options with Tooltip **/
    .dropdown-list-option:not(:has(.tooltip-text)),
    .dropdown-list-option .tooltip-text {
      padding: var(--size-small) var(--size-medium) var(--size-small) calc(var(--size-extra-large) + var(--size-small));
    }

    .dropdown-list-option.hide-indicator:not(:has(.tooltip-text)),
    .dropdown-list-option.hide-indicator .tooltip-text {
      padding-left: var(--size-medium);
    }

  .dropdown-list-option:focus-within {
    outline: 0;
  }

  .dropdown-list-option:focus-within,
  .dropdown-list-option:hover,
  .dropdown-list-option:active {
    --dropdown-item-background: var(--white);
    --dropdown-color: var(--black-dark);

    [data-color-mode="dark"] & {
      --dropdown-item-background: var(--black);
    }
  }

  .dropdown-list-option:first-child {
    padding-top: var(--size-medium);
  }

  .dropdown-list-option:last-child {
    padding-bottom: var(--size-medium);
  }

  /** Non-option children **/
  .dropdown-list hr {
    background: var(--dropdown-hr);
  }

  /** Icons (e.g. for displaying checkmarks) **/
  .dropdown-list-option .icon {
    position: absolute;
    left: 0;
    margin-left: var(--size-medium);
  }
