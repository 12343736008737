.card {
  --card-background: var(--app-background);
  --card-border: transparent;
  --card-header-background: var(--card-background);
  /* text colors are overridden by redefining --app-color and --app-header-color */

  background: var(--card-background);
  border: 1px solid var(--card-border);
  border-radius: var(--border-radius);
  color: var(--app-color);
}

  .card-header {
    background: var(--card-header-background);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    border-bottom: 1px solid var(--card-border);
    padding: var(--size-medium);
    gap: var(--size-medium);
  }

  .card-actions {
    background: var(--card-header-background);
    padding: var(--size-small) var(--size-medium);
    gap: var(--size-medium);

    .card-header + & {
      border-bottom: 1px solid var(--card-border);
    }

    .card-content + & {
      border-top: 1px solid var(--card-border);
    }
  }

  .card-content {
    padding: var(--size-medium);
  }

/** Card variants **/
.card-primary {
  --card-background: var(--white-light);
  --card-border: var(--grey-transparent);

  [data-color-mode="dark"] & {
    --card-background: var(--black-dark);
    --card-border: var(--black);
  }
}

.card-secondary {
  --card-border: var(--grey-transparent);

  [data-color-mode="dark"] & {
    --card-border: var(--white-transparent);
  }
}

.card-highlight {
  --card-background: var(--grey);
  --app-header-color: var(--white-light);
  --app-color: var(--white);
  --label-color: var(--white-dark);
  --highlight-color: var(--white-light);
  --card-border: var(--grey-transparent);
}

.card-transparent {
  --card-background: transparent;
  --card-border: transparent;
  --card-header-background: transparent;
}

/** Closable Cards **/
.card-closable {}

/** Expandable Cards **/
.card-expandable .icon-expandable {
  vertical-align: top;
  font-size: var(--font-size-8);
  margin-right: var(--size-small);
}

.card-not-expanded .card-header {
  border-radius: var(--border-radius);
  border-bottom: 0;
}

.card[role="dialog"] {
  box-shadow: 0 0px 12px 0px var(--black-transparent);
  min-width: 28rem;
  max-width: 40rem;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

/**
  CSS-only animatable height!
  It requires 2 wrappers: 1 to display grid, and 1 to hide the content
  See https://www.stefanjudis.com/snippets/how-to-animate-height-with-css-grid/
**/

.card-expandable-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows .18s ease-in-out;

  &[aria-hidden="false"] {
    grid-template-rows: 1fr;
  }

  .card-expandable-content {
    overflow: hidden;
  }
}
