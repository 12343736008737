.date-range-container[role="dialog"] {
  padding: var(--size-medium) var(--size-small);
  margin-top: var(--size-small);
  min-width: 20rem;

  --border: var(--white-dark);

  [data-color-mode="dark"] & {
    --border: var(--black-light);
  }
}

.rdp {
  --rdp-cell-size: 2.75rem;
  --rdp-background-color: var(--blue);
  --rdp-outline-width: 1px;
  --rdp-outline: var(--rdp-outline-width) solid var(--black); /* Outline border for focused elements */
}

  .rdp-caption {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: baseline;
    gap: var(--size-medium);

    padding: 0 0 var(--size-medium);
    border-bottom: 1px solid var(--border);
  }

  .rdp-nav {
    display: contents;
  }

    .rdp-nav_button_previous {
      order: 1;
      --button-border: transparent;
      --button-background: transparent;
    }

    .rdp-nav_button_next {
      order: 3;
      --button-background: transparent;
      --button-border: transparent;
    }

      .rdp-nav_icon {
        width: 1rem;
        height: 1rem;
      }

    .rdp-caption_dropdowns {
      order: 2;
      display: flex;
      justify-content: center;
      column-gap: var(--size-medium);
    }

/**
 * Calendar (it's just a table)
 **/

.rdp-head_cell {
  border: 0;
  border-bottom: 1px solid var(--border);
  text-align: center;
  width: var(--rdp-cell-size);
  padding: var(--size-medium) 0;
}

.rdp-table {}
  .rdp-head {}
  .rdp-tbody {}
    .rdp-row {}
      .rdp-cell {
        width: var(--rdp-cell-size);
        height: var(--rdp-cell-size);
      }


/** Day & weeknumber buttons inside our table **/
.rdp-day,
.rdp-weeknumber {
  display: block;
  width: 100%;
  height: 100%;

  [data-color-mode="dark"] & {
    --button-background: transparent;
  }
}

  .rdp-day_outside:not(:is(.rdp-day_selected, .rdp-day_disabled)) {
    --button-color: var(--grey);
  }

  .rdp-day:not(:is(:hover, :focus)) {
    --button-border: transparent;
  }

    .rdp-day.button-active {
    --button-background: var(--white);
    --button-color: var(--black);

    [data-color-mode="dark"] & {
      --button-background: var(--black-light);
      --button-color: var(--white);
    }
  }

  .rdp-weeknumber {
    --button-border: transparent;
    font-weight: var(--font-weight-medium);
    color: var(--grey-dark);
  }

/** Table Footer **/
.rdp-tfoot td {
  border-top: 1px solid var(--border);
}


/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: var(--rdp-outline-width) solid transparent;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-caption_label {
  border-radius: var(--border-radius);
}

.rdp-caption_label > .button {
  display: inline-block;
}

.rdp-dropdown:hover:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
  outline: var(--rdp-outline);
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}