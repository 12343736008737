:root {
  color-scheme: light dark; /* both are supported */

  --logo-height: 4.5rem;

  --app-background: var(--white);
  --app-color: var(--black);
  --app-header-color: var(--black-dark);
  --highlight-color: var(--black-dark);
  --label-color: var(--grey);
  --link-color: var(--blue);

  /** App header **/
  --app-header-background: var(--white-light);
  --app-header-shadow: var(--black-transparent);
  --app-header-border: var(--grey-transparent);

  --grid-col-breakpoint: 10rem;

  --border-radius: 5px;

  --size-extra-small: 3px;
  --size-small: 6px;
  --size-medium: 12px;
  --size-large: 18px;
  --size-extra-large: 24px;

}

[data-color-mode="dark"] {
  --app-background: var(--black);
  --app-color: var(--white);
  --app-header-color: var(--white-light);
  --highlight-color: var(--white-light);
  --label-color: var(--grey);
  --link-color: var(--blue);
  --app-header-background: var(--black-transparent);
  --app-header-shadow: var(--black-dark);
  --app-header-border: var(--black-transparent);
}

html {
  background-color: var(--app-background);
  color: var(--app-color);
}

hr {
  background: var(--app-header-border);
}

/**
  App layout
**/

html, body {
  height: 100vh;
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  display: grid;
  grid-template-columns: minmax(15rem, auto) 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
    "topheader topheader"
    "sidebar header"
    "sidebar content";
}

  .app-header {
    grid-area: header;
  }

  .app-content {
    grid-area: content;
    padding: var(--size-medium);
    overflow: auto;
    will-change: scroll-position;
    position: relative;
  }

  /* wally layout - occupy full grid when sidebar is not present */
  .app-content:only-child {
    grid-column: 1 / -1;
  }

  .top-header {
    grid-area: topheader;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--app-header-background);
    border-bottom: 1px solid var(--app-header-border);
    padding-right: 1rem;
  }

  .top-header-menus {
    gap: var(--size-small);
  }

  .header-menu-current-page {
    color: var(--teal);
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: .25em;
    text-decoration-color: var(--teal);
  }

  .app-sidebar {
    grid-area: sidebar;
    display: grid;
    grid-template-rows: auto 1fr;
    background: var(--app-header-background);
  }

    .app-menu {
      overflow-y: auto;
    }

.app-header {
  background: var(--app-header-background);
  border-bottom: 1px solid var(--app-header-border);
  box-shadow: 0px 8px 18px -18px var(--app-header-shadow);

  display: flex;
  align-items: flex-start;
  padding: var(--size-medium);
  gap: var(--size-medium);

  .select-space,
  .actions,
  .select-filter,
  .select-time,
  .select-settings {
    display: flex;
    align-items: center;
    gap: var(--size-medium);
  }
}

/**

  Adjustments for unusual HTML

**/

/**
  Sometimes we need to render our "Options" inside the content. This will display the button inside the header.
  E.g. Analyse Booking Data & Booking Utilization.
**/

.app-content .app-more-settings {
  position: fixed;
  right: 1rem;
  margin-top: -4.5rem;
  display: flex;
  gap: var(--size-medium);
}

/** On pages without the full header, ensure it has the same height as the logo **/
.app-header .app-header-content {
  display: grid;
  place-content: center;
  min-height: calc(var(--logo-height) - 2 * var(--size-medium));
}


/** Login page - extend the header to full-width **/
.app:has(.login-page) {
  grid-template-columns: 1fr;
}

  .login-page .card {
    width: 24rem;
  }


/**

  Logo

**/
.app-logo {
  box-sizing: border-box;
  display: block;
  height: var(--logo-height);
  margin: 0;
  padding: var(--size-small);
  line-height: 1;
  color: #333542; /* match with marketing materials */
}

[data-color-mode="dark"] .app-logo {
  color: var(--white-light);
}

  .app-logo .logo-text {
    fill: currentColor;
  }

    .app-logo figure {
      margin: 0;
      aspect-ratio: 1180/318;
      max-width: 100%;
      max-height: 100%;
    }

    .app-logo svg {
      height: 100%;
      width: 100%;
    }

    .app-logo figcaption {
      position: absolute;
      left: 0; right: 0;
      width: 0; height: 0;
    }

/**

  Menu

**/

.app-menu {
  --menu-highlight: var(--white);

  background: var(--app-header-background);
  border-right: 1px solid var(--app-header-border);
  color: var(--app-color);
  padding-bottom: var(--size-large);

  [data-color-mode="dark"] & {
    --menu-highlight: var(--white-transparent);
  }

  /**
   * Shared menu-header styles
  **/
  .menu-header {
    padding: var(--size-medium);
    margin-block: var(--size-small) 0;
  }

  /**
   * Shared menu-item styles
  **/
  .menu-item {
    display: block;
    font-weight: var(--font-weight-header);
    padding-inline: var(--size-medium);
    padding-block: var(--size-small);

    &[disabled] {
      cursor: not-allowed;
    }

    &:focus {
      outline: 0;
    }

    &:focus,
    &:hover {
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: .25em;
      text-decoration-color: var(--label-color);
    }

    &[aria-current="page"] {
      color: var(--teal);
      font-weight: var(--font-weight-header);
      text-decoration-line: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: .25em;
      text-decoration-color: var(--teal);
    }
  }

  /* E.g. the Home menu */
  > .menu-item:first-child {
    padding-block: var(--size-medium);
  }

  /**
   * Primary level
   * use direct ascendent selector to prevent these styles
   * from being inherited
  **/
  > .menu-section {
    border-top: 1px solid var(--app-header-border);

    > .menu-header {
      color: var(--label-color);
      font-size: var(--font-size-8);
      font-weight: var(--font-weight-header);
      letter-spacing: var(--letter-spacing-3);
      text-transform: uppercase;
    }

    /**
     * secondary level styles
    **/
    .menu-section {

      .menu-header {
        margin-block: 0;
        font-weight: var(--font-weight-header);
        padding-inline: var(--size-medium);
        padding-block: var(--size-small);
        cursor: pointer;


        &::after {
          float: right;
          content: "";
          display: inline-block;
          width: 12px;
          height: 12px;
          background: url("../../static/fontawesome-free-6.6.0-web/svgs/solid/chevron-down.svg") center center no-repeat;
          background-size: contain;
          transition: transform .18s ease-out;
        }

        [data-color-mode="dark"] &::after {
          filter: invert(1);
        }

        &:hover {
          text-decoration-line: underline;
          text-decoration-thickness: 2px;
          text-underline-offset: .25em;
          text-decoration-color: var(--label-color);
        }
      }

      /**
       * this wrapper receives the grid styles
       * it's required otherwise scrolling will be a bit unpredictable
       **/
      .foldable-menu-items {
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows .18s ease-out;

        > .menu-items {
          overflow: hidden;
        }
      }

      /** expanded state **/
      &[aria-expanded="expanded"],
      &:has([aria-current="page"]) {
        .foldable-menu-items {
          grid-template-rows: 1fr;
        }

        .menu-header::after {
          transform: rotate(180deg);
        }
      }

      &:has([aria-current="page"]) > .menu-header {
        cursor: default;
        color: var(--teal);
        text-decoration-line: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: .25em;
        text-decoration-color: var(--teal);
      }

      .menu-item {
        padding-left: var(--size-extra-large);
        font-weight: var(--font-weight-regular);
      }
    }
  }

}

/**

  Options

**/
.more-settings ,
.more-settings-datasource {
  display: grid;
  grid-template-columns: minmax(12rem, auto) 1fr;
  gap: var(--size-medium);
  padding-top: var(--size-medium);
  padding-left: var(--size-medium);
  padding-right: var(--size-medium);
  align-items: center;
  overflow: auto;

  &.small-label {
    grid-template-columns: minmax(4rem, auto) 1fr;
  }
}

  .more-settings hr {
    grid-column: 1 / 3;
  }

  .more-settings-header {
    grid-column: 1 / 3;
    font-size: var(--font-size-8);
    color: var(--label-color);
    place-self: center;
  }

    .more-settings-item-control + .more-settings-header {
      margin: var(--size-large) 0 0;
    }

    .more-settings-item-label {
      justify-self: end;
    }

    .more-settings div[data-feature] {
      display: contents;
    }

/**

  Dialog

**/
.dialog-overlay {
  z-index: 1000;
  display: grid;
  justify-content: center;
  align-items: center;
  background: var(--black-transparent-light);
  animation: fadeInBackground 0.05s ease-in-out;
}
@keyframes fadeInBackground {
  0% {
    background: none;
  }
  100% {
    background: var(--black-transparent-light);
  }
}

.dialog-footer {
  gap: var(--size-medium)
}

[role="dialog"] {
  z-index: 1000;
}

/**

  Grid helper classes

**/

.display-grid {
  display: grid;
}

.display-inline-grid {
  display: inline-grid;
}

.grid-columns {
  display: grid;
  gap: 0 var(--size-small);
  grid-template-columns: repeat(
    auto-fit,
    minmax(var(--grid-col-breakpoint), 1fr)
  );
}

.grid {
  display: grid;

  &.-horizontal {
    grid-template-columns: repeat(auto-fit, var(--grid-col-breakpoint));
  }
}

.gap-1, .gap-extra-small  { gap: var(--size-extra-small); }
.gap-2, .gap-small        { gap: var(--size-small); }
.gap-3, .gap-medium       { gap: var(--size-medium); }
.gap-4, .gap-large        { gap: var(--size-large); }
.gap-5, .gap-extra-large  { gap: var(--size-extra-large); }
.gap-x-1, .gap-x-extra-small  { row-gap: var(--size-extra-small); }
.gap-x-2, .gap-x-small        { row-gap: var(--size-small); }
.gap-x-3, .gap-x-medium       { row-gap: var(--size-medium); }
.gap-x-4, .gap-x-large        { row-gap: var(--size-large); }
.gap-x-5, .gap-x-extra-large  { row-gap: var(--size-extra-large); }
.gap-y-1, .gap-y-extra-small  { column-gap: var(--size-extra-small); }
.gap-y-2, .gap-y-small        { column-gap: var(--size-small); }
.gap-y-3, .gap-y-medium       { column-gap: var(--size-medium); }
.gap-y-4, .gap-y-large        { column-gap: var(--size-large); }
.gap-y-5, .gap-y-extra-large  { column-gap: var(--size-extra-large); }


/**

  Visually hidden
  See https: //www.scottohara.me/blog/2017/04/14/inclusively-hidden.html

**/

.visually-hidden:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.building-settings-opening-hours {
  display: grid;
  grid-template-columns: 85px 285px;
  row-gap: var(--size-medium);
  align-items: center;

  .more-settings-item-label {
    justify-self: start;
  }
}
